import { markPerformanceAPI, measurePerformanceAPI } from '../../analytics-library/entry';
import { getJSON } from '../../sharedModules/api/apiUtils';
import { createMerchantsGetter } from '../../sharedModules/api/searchApi/createMerchantsGetter';
import { getSite } from '../../utils/getSite';
import getUrlParameters from '../../utils/getUrlParameters';

import getKeywords from './getKeywords';
import init from './init';

const runHawklinks = async (): Promise<void> => {
  const keywords = await getKeywords();
  const site = getSite(document.location.href, '', keywords);
  const { area } = getUrlParameters(['area']);

  try {
    const fetchedMerchants = await createMerchantsGetter(getJSON)({
      area,
      site,
    });

    await init({
      site,
      merchants: fetchedMerchants.merchants,
      area: fetchedMerchants.area,
      tsid: fetchedMerchants.tsid,
      skimlinksId: fetchedMerchants.skimlinksId,
    });
  } catch (e) {
    await init({
      site,
      merchants: [],
      area: '',
    });
  }
};

export const runHawklinksWithPerformanceMeasurement = async (): Promise<void> => {
  markPerformanceAPI('Entry script loaded', { detail: 'HAWKLINKS' });
  await runHawklinks();
  markPerformanceAPI('Hawklinks process completed', { detail: 'HAWKLINKS' });
  measurePerformanceAPI('Time between from script loaded to rewritten link return', {
    start: 'Entry script loaded',
    end: 'Hawklinks process completed',
    detail: `HAWKLINKS`,
  });
};
